import React from "react"
import { useQuery } from "@apollo/client";
import gql from 'graphql-tag';
import { Loading } from '../components/Widgets'

const CurrentUserContext = React.createContext()

export const MeProvider = ({ children }) => {
    const {data, loading, refetch: refetchMe } = useQuery(gql`
        query {
            me {
                id
                username
                firstName
                lastName
                email
                
                # all the different possible roles:
                isMonitor
                isNurse
                isInvestigator
                isLabMember
                isBiobankMember
                isAdministrator
                isMonitor
                isPathologist
                isPathologistPythia
                isMabMember
                isReportAuthor
                isReportReviewer
                isMedicalReviewer
                isGroupMonitor
                isOperator
                isDeveloper

                organization {
                    id
                    breastCode
                    shortName
                    fullName
                    blockedFromRecruitment
                }
            }
        }
    `)
    if (loading) return <Loading />
    const { me } = data
    return (
        <CurrentUserContext.Provider value={{ me, refetchMe }}>
            {children}
        </CurrentUserContext.Provider>
    )
}

export const useMe = () => React.useContext(CurrentUserContext)
