import { gql, useQuery } from '@apollo/client'
import {
    Container,
    Card,
    CardContent,
    Typography,
    Box,
    Stack,
    Alert,
} from '@mui/material'
import { useMe } from '../providers/Me'

import { Loading, MyLink } from "./Widgets"


const ContactBlock = ({ name, email, title }) => (
    <Box sx={{ py: 2 }}>
        <Typography variant="body1">{name}</Typography>
        {title && (
            <Typography variant="body2" color="text.secondary">{title}</Typography>
        )}
        <MyLink href={`mailto:${email}`}>{email}</MyLink>
    </Box>
)

const ContactInfo = () => (
    <Container sx={{ mb: 2 }}>
        <Card>
            <CardContent>
                <Typography variant="h5">Contact</Typography>
                <ContactBlock name="The AURORA Team" email="aurora.bighq@bigagainstbc.org" />
                <ContactBlock name="Alice RAIMBAULT" email="alice.raimbault@bigaginstbc.org" title="Project Manager" />
                <ContactBlock name="Alexandre IRRTHUM" email="alexandre.irrthum@bigagainstbc.org" title="Senior Scientitic Advisor" />
                <ContactBlock name="Shean MASSEY" email="shean.massey@bigagainstbc.org" title="Software Developer" />
            </CardContent>
        </Card>
    </Container>
)

const SiteDocuments = ({ documents }) => {

    if (documents.length === 0) return <></>

    return (
        <Container sx={{ mb: 2 }}>
            <Card>
                <CardContent>
                    <Typography variant="h5">Site Closure Logs</Typography>
                    <ul className="list-unstyled">
                        {documents.map((doc, i) => (
                            <li key={i}><MyLink href={doc.path}>{doc.name}</MyLink></li>
                        ))}
                    </ul>
                </CardContent>
            </Card>
        </Container>
    )
}

const Documents = () => (
    <Container sx={{ mb: 2 }}>
        <Card>
            <CardContent>
                <Typography variant="h5">Study Documents</Typography>
                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_protocol_v1_0.pdf?cb=20190222">Protocol v1.0</MyLink></li>
                    <li><MyLink href="/static/AURORA_protocol_v2_0.pdf?cb=20190222">Protocol v2.0</MyLink></li>
                    <li><MyLink href="/static/AURORA_protocol_v3_0.pdf?cb=20190222">Protocol v3.0</MyLink></li>
                    <li><MyLink href="/static/AURORA_protocol_v4_0.pdf?cb=20230712">Protocol v4.0</MyLink></li>
                </ul>
                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_PIS_ICF_v1_0.pdf?cb=20190222">PIS-ICF v1.0</MyLink></li>
                    <li><MyLink href="/static/AURORA_PIS_ICF_v1_1.pdf?cb=20190222">PIS-ICF v1.1</MyLink></li>
                    <li><MyLink href="/static/AURORA_PIS_ICF_v2_0.pdf?cb=20190222">PIS-ICF v2.0</MyLink></li>
                    <li><MyLink href="/static/AURORA_PIS_ICF_v3_0_new.pdf?cb=20190222">PIS-ICF v3.0 (new patients)</MyLink></li>
                    <li><MyLink href="/static/AURORA_PIS_ICF_v3_0_registered.pdf?cb=20190222">PIS-ICF v3.0 (registered patients)</MyLink></li>
                    <li><MyLink href="/static/AURORA_PIS_ICF_v4_0.pdf?cd=20230712">PIS-ICF v4.0</MyLink></li>
                </ul>
                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_SAE_form.pdf?cb=20230712">SAE Form</MyLink></li>
                    <li><MyLink href="/static/AURORA_SAE_guidelines.pdf?cb=20230712">SAE Guidelines</MyLink></li>
                </ul>
                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_pi_signature.pdf?cb=20230904">PI Signature Form</MyLink></li>
                    <li><MyLink href="/static/AURORA_kit_resupply_form.pdf?cb=20230712">Kit Re-supply Form</MyLink></li>
                    <li><MyLink href="/static/AURORA_template_site_sig_log.pdf?cb=20190222">Site Signature Log Template</MyLink></li>
                    <li><MyLink href="/static/AURORA_samples_shipment_IBBL_template.xlsx?cb=20230719">Samples Shipment Template</MyLink></li>
                </ul>
                <ul className="list-unstyled">
                    <li><MyLink href="/static/AURORA_lab_manual.pdf?cb=2023">Lab Manual</MyLink></li>
                    <li><MyLink href="/static/AURORA_sample_collection_quick_guide.pdf?cb=20230719">Sample Collection Quick Guide</MyLink></li>
                    <li><MyLink href="/static/AURORA_IT_platform_queries_manual.pdf?cb=20190222">Queries Manual</MyLink></li>
                </ul>
            </CardContent>
        </Card>
    </Container>
)


export const Videos = () => (
    <Container>
        <Card sx={{ p: 2 }}>
            <Stack direction="column" spacing={2}>
                <Alert>
                    This video documentation is confidential and intended solely for the individual addressed.
                    Unauthorized sharing, use, or distribution of this material is strictly prohibited.
                </Alert>
                <div className="video-container">
                    <iframe className="video" src="https://www.youtube.com/embed/ZFTV9IpbZTs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div className="video-container">
                    <iframe className="video" src="https://www.youtube.com/embed/nlkDzcXJv2U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
            </Stack>
        </Card>
    </Container>
)

export const Help = () => (
    <>
        <ContactInfo />
    </>
)


export const UserDownloads = () => {
    const { data, loading, error } = useQuery(gql`
        query SiteDocumentsQuery {
            siteDocuments {
                name
                path
            }
        }
    `)
    return loading 
        ? <Loading />
        : <SiteDocuments documents={data.siteDocuments} />
}


export const StudyDocuments = () => {
    const { me } = useMe()
    return (
        <>
            <Documents />
        </>
    )
}
