import { useQuery, gql } from "@apollo/client"
import { Loading, MyTextField } from "./Widgets"
import { useState, useEffect } from "react"

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
    Stack,
    Container,
    Card,
    CardContent,
    TablePagination,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@mui/material'
import { useDebounce } from "../Hooks"
import { useHistory } from "react-router-dom"
import { useMe } from "../providers/Me"


export const PatientActivityListing = () => {
    const { me } = useMe()
    const history = useHistory()
    const [searchTerm, setSearchTerm] = useState("")
    const [stateFilter, setStateFilter] = useState(["Activated"])
    const debouncedSearchTerm = useDebounce(searchTerm, 350)
    const [searchArgs, setSearchArgs] = useState({
        q: debouncedSearchTerm,
        skip: 0,
        first: 25,
    })
    useEffect(() => {
        setSearchArgs(s => ({...s, q: debouncedSearchTerm, skip: 0}))
    }, [debouncedSearchTerm])
    const { data, loading, errors } = useQuery(gql`
        query PatientActivityListing ( $searchArgs: SearchPatientActivityInput!, $stateFilter: [String!]! ) {
            patientActivities ( searchArgs: $searchArgs, stateFilter: $stateFilter ) {
                page
                count
                results {
                    id
                    patient {
                        id
                        integrateIdentifier
                        registrationDate
                        treatingInvestigator {
                            id
                            title
                            lastName
                        }
                        site {
                            id
                            fullName
                            shortName
                            breastCode
                        }
                    }
                    activity {
                        id
                        name
                        displayName
                    }
                }
            }
        }
    `, {
        variables: {
            searchArgs,
            stateFilter,
        },
    })

    const toggleStateFilter = state => {
        if (stateFilter.indexOf(state) === -1) {
            setStateFilter([...stateFilter, state])
        } else {
            setStateFilter(stateFilter.filter(s => s !== state))
        }
    }

    const showSiteDetails = false;

    const patientActivities = data?.patientActivities;
    const results = data?.patientActivities?.results;

    if (!data) return <Loading />

    const ACTIVITY_MAP = {
        'Activated': 'To do',
        'Succeeded': 'Done',
    }

    const searchLabel = (me?.isMonitor)
        ? `Search for an activity by name, patient ID, site code, or investigator`
        : `Search for an activity by name or patient ID`

    return (
        <Container>
                <Card>
                    <CardContent>
                        <Stack direction="column" spacing={2}>
                            <MyTextField
                                label={searchLabel}
                                value={searchTerm}
                                onChange={e => {
                                    setSearchTerm(e.target.value)
                                }}
                                autoFocus
                                />

                            <FormGroup row sx={{ mb: 10 }}>
                                {["Activated", "Succeeded"].map(s => (
                                    <FormControlLabel key={s} control={
                                        <Checkbox
                                            onChange={() => toggleStateFilter(s)}
                                            color="primary"
                                            checked={stateFilter.indexOf(s) > -1}
                                            />
                                        }
                                        labelPlacement="end"
                                        label={ACTIVITY_MAP[s]}
                                        />
                                ))}
                            </FormGroup>

                            {loading 
                                ? (<Loading />) 
                                : (
                                    <>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Patient ID</TableCell>
                                                    <TableCell>Activity</TableCell>
                                                    <TableCell>Registration Date</TableCell>
                                                    {showSiteDetails && (
                                                        <>
                                                            <TableCell>Site</TableCell>
                                                        </>
                                                    )}
                                                    <TableCell>Investigator</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {results.map(pa => (
                                                    <TableRow key={pa.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                                                        history.push(`/patient/${pa.patient.integrateIdentifier}/activity/${pa.activity.name}`)
                                                    }}>
                                                        <TableCell>{pa.patient.integrateIdentifier}</TableCell>
                                                        <TableCell>{pa.activity.displayName}</TableCell>
                                                        <TableCell>{pa.patient.registrationDate}</TableCell>
                                                        {showSiteDetails && (
                                                            <>
                                                                <TableCell>{pa.patient.site.breastCode}</TableCell>
                                                            </>
                                                        )}
                                                        <TableCell>
                                                            {Boolean(pa.patient.treatingInvestigator)
                                                                ? <>{pa.patient.treatingInvestigator.title} {pa.patient.treatingInvestigator.firstName} {pa.patient.treatingInvestigator.lastName}</>
                                                                : <>N/A</>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            count={patientActivities.count}
                                            page={patientActivities.page}
                                            rowsPerPage={searchArgs.first}
                                            rowsPerPageOptions={[ 10, 25, 50, 100, { value: -1, label: "ALL" }]}
                                            onPageChange={(e, newPage) => {
                                                setSearchArgs({ ...searchArgs, skip: newPage * searchArgs.first })
                                            }}
                                            onRowsPerPageChange={e => {
                                                setSearchArgs({ ...searchArgs, first: e.target.value })
                                            }}
                                            />
                                    </>
                                )
                            }
                        </Stack>
                    </CardContent>
                </Card>
            </Container>
    )
}