import React from 'react';

import { createTheme, ThemeProvider, styled, useTheme } from '@mui/material/styles';
import { red, amber, pink, blue, green, lightBlue, grey, blueGrey, brown, deepPurple, indigo, purple, deepOrange, lime } from '@mui/material/colors';
import { useLocalStorage } from '../Hooks';

const CurrentThemeContext = React.createContext()

// SEE https://github.com/mui/material-ui/pull/26545
// on setting the top navbar to a specific color when dark mode is enabled!

const darkThemeInstance = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: blue[500],
    },
    secondary: {
      main: lightBlue[500],
    },
    success: {
      main: green[500],
    }
  },
  typography: {
    fontFamily: '"Ubuntu", "Lato", "San Francisco", Helvetica, Arial, sans-serif',
  }
})
  
const lightThemeInstance = createTheme({
  palette: {
    mode: 'light',
    text: {
      primary: '#000',
      secondary: '#777',
      disabled: '#aaa',
    },
    primary: {
      // main: '#52e052', // this is the same green from highways in the "light" map
      // main: blueGrey[500],
      
      // main: '#191927',
      contrastText: '#fff',
      // main: pink[500],
      // main: blue[500],
      // main: deepOrange[500],
      // main: green[500],
      // main: amber[500],
      main: indigo[500], 
    },
    secondary: {
      main: grey[700],
      contrastText: '#f6f7f9',
    },
    success: {
      main: blueGrey[500],
      contrastText: '#fff',
    },
    info: {
      main: blue.A200,
      contrastText: blue[50],
    },
    warning: {
      main: amber.A200,
      contrastText: '#333',
    },
    error: {
      main: '#c4314b',
      // main: red.A400,
      contrastText: red[50],
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          // backgroundColor: '#191927',
          backgroundColor: 
            window.location.hostname == "platform.aurora-mbc.org" 
              ? '#191927'
              : window.location.hostname == "training.aurora-mbc.org"
              ? '#f00'
              : window.location.hostname == "localhost"
              ? 'black'
              : 'darkgreen'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#f6f7f9",
        },
      },
    },
    // MuiCardHeader: {
    //   styleOverrides: {
    //     root: {
    //       // backgroundColor: '#52e052',
    //       // backgroundColor: deepOrange[800],
    //       backgroundColor: blueGrey[700],
    //       // color: '#fff',
    //     }
    //   }
    // }
  },
  typography: {
    fontFamily: '"Ubuntu", "Lato", "San Francisco", Helvetica, Arial, sans-serif',
    // fontSize: '22px',
  }
})


const THEME_MAP = {
    'light': lightThemeInstance,
    'dark': darkThemeInstance,
}


export const MyThemeProvider = ({ children }) => {
    const [ theme, setTheme ] = useLocalStorage('theme', 'light')
    const themeInstance = THEME_MAP[theme]
    return (
        <CurrentThemeContext.Provider value={{ theme, setTheme }}>
            <ThemeProvider theme={themeInstance}>{children}</ThemeProvider>
        </CurrentThemeContext.Provider>
    )
}


export const useMyCurrentTheme = () => React.useContext(CurrentThemeContext)
