import {
  Redirect,
  // BrowserRouter as Router,
  // Switch,
  Route, Switch, useLocation, useRouteMatch,
  // Redirect,
} from "react-router-dom";

import { useMe } from "../providers/Me"
import { useQuery, gql, useMutation } from "@apollo/client"
import { Loading, MyTextField, AppBarButton } from "./Widgets"
import { useState, useEffect, useReducer, useRef } from "react"

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import InfoIcon from '@mui/icons-material/Info';

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Stack,
  Container,
  Card,
  CardContent,
  TablePagination,
  Typography,
  Box,
  Tab,
  Tabs,
  Button,
  Checkbox,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Alert,
  InputAdornment,
  IconButton,
  Chip,
  Collapse,
  Paper,
  AppBar,
  Toolbar,
} from '@mui/material'
import { useDebounce, useLocalStorage, useStateFilters } from "../Hooks"
import { useParams, useHistory } from "react-router-dom"
import { ActivityGraph, ActivityGraphLegend } from "./ActivityGraph"
import { CreatePatientQuery } from "./Queries";


const formatRawValue = v => {
  if (v === null || v === undefined || v === '') return <Box component="span" sx={{ color: 'text.disabled' }}>blank</Box>
  if (typeof v === 'boolean') return v ? 'Yes' : 'No';
  return String(v)
}


export const SDVCard = ({ sdv }) => {
  return (
    <Alert severity="success" sx={{ my: '1rem' }}>
      <Typography variant="body1" gutterBottom component="div">
        Source Document Verified
      </Typography>
      <Typography variant="body2" gutterBottom component="div">
        {sdv?.createdBy?.title} {sdv?.createdBy?.firstName} {sdv?.createdBy?.lastName} on {new Date(sdv?.createdAt).toLocaleString()}
      </Typography>
      {sdv?.comment && (
        <Typography variant="body2" gutterBottom component="div">
          Additional comments: {sdv?.comment}
        </Typography>
      )}
    </Alert>
  )
}

const sdvReducer = (state, action) => {
  switch (action.type) {
    case 'toggle_patient_activity':
      return {
        ...state,
        patientActivityIds: state.patientActivityIds.includes(action.id)
          ? state.patientActivityIds.filter(id => id !== action.id)
          : [...state.patientActivityIds, action.id]
      }
    case 'set_comment':
      return {
        ...state,
        comment: action.comment
      }
    case 'reset':
      return {
        ...state,
        patientActivityIds: [],
        comment: '',
      }
    default:
      throw new Error();
  }
}


const CollapsableRow = ({ row, state, dispatch }) => {
  // const [open, setOpen] = useState(false)

  const open = state.patientActivityIds.includes(row.id)
  const ref = useRef();

  useEffect(() => {
    if (open) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [open])

  return (
    <>
      <TableRow onClick={() => dispatch({ type: 'toggle_patient_activity', id: row.id })} ref={ref}>
        <TableCell padding="checkbox">
          <IconButton size="small">
            <Checkbox checked={open} />
          </IconButton>
        </TableCell>
        <TableCell>
          {row.activity.displayName}
        </TableCell>
        <TableCell align="right">
          {row.sdv?.invalidated === false ? (
            <Chip label="Completed" color="success" />
          ) : (
            <Chip label="Missing" color="warning" />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 2, border: 1, borderRadius: 1, p: 2 }}>
              {row.attributeValues.length === 0
                ? (
                  <></>
                ) : (
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Attribute</TableCell>
                        <TableCell>Value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.attributeValues.map(av => (
                        <TableRow key={av.id}>
                          <TableCell>{av.attribute.displayName}</TableCell>
                          <TableCell>{formatRawValue(av.value)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}

              {row.sdv?.invalidated === false && (
              <SDVCard sdv={row.sdv} />
              )}

            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export const CreateSDV = props => {
  // const { ssid } = useParams()
  const { ssid, onChange } = props

  const [state, dispatch] = useReducer(sdvReducer, {
    ssid,
    patientActivityIds: [],
    comment: '',
  })

  const { data, loading, error, refetch } = useQuery(gql`
      query PatientAttributeValuesForSDV ($ssid: ID!) {
          sdvDataTables (ssid: $ssid) {
              id
              state
              activity {
                  id
                  name
                  displayName
              }
              attributeValues {
                  id
                  attribute {
                      id
                      name
                      displayName
                  }
                  value
                  sdv {
                      id
                      comment
                      createdAt
                      createdBy {
                          id
                          username
                          firstName
                          lastName
                          email
                      }
                  }
              }
          }
      }
  `, { variables: { ssid } })

  const [createSdv, { error: createSDVError }] = useMutation(gql`
      mutation CreateSDVMutation ( $patientActivityIds: [ID!]!, $comment: String! ) {
        createSdv ( input: { patientActivityIds: $patientActivityIds, comment: $comment } ) {
          id
        }
      }
  `)

  const onSubmit = () => {
    createSdv({ variables: { ...state } })
      .then(({ data }) => {
        // alert(`created SDV ${JSON.stringify(data)}`)
        dispatch({ type: 'reset' })
        refetch()
        onChange()
      })
  }

  if (loading) return <Loading />
  if (error) return 'error...'

  const verifyCheckedBtnText = state.patientActivityIds.length <= 1
    ? 'Verify Selected Document'
    : `Verify ${state.patientActivityIds.length} Selected Documents`
    ;

  const isValid = state.patientActivityIds.length > 0;

  return (
    <>
      {/**
      <Typography variant="h6" sx={{ my: 2 }}>Source Document Verification</Typography>
      */}

      <Alert severity="info" sx={{ my: 2 }}>
        <Typography variant="body1" gutterBottom component="div">
          This form is designed for monitors to verify the authenticity and accuracy of clinical trial documents.
        </Typography>
        <Typography sx={{ fontWeight: 'bold', py: '1.4rem' }}>Steps to Complete the Form:</Typography>
        <Typography variant="body1" gutterBottom component="div">
          <strong>Review Documents:</strong> Please review each document thoroughly.
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
          <strong>Mark the Checkboxes:</strong> For each document you have reviewed and verified, mark the corresponding checkbox.
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
          <strong>Optional Comments:</strong> If you have any additional observations or comments about the documents, please provide them in the 'Additional Comments' section. This step is optional.
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
          <strong>Submit Verification:</strong> Click the <strong>VERIFY SELECTED DOCUMENTS</strong> button to submit your verifications.
        </Typography>
        <Typography variant="body1" gutterBottom component="div">
          Remember, your accurate verification is crucial for the integrity of the clinical trial. Thank you for your diligence and contribution.
        </Typography>
      </Alert>

      <Paper sx={{ my: 2 }}>

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Activity</TableCell>
              <TableCell align="right">SDV State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.sdvDataTables.map((row, i) => <CollapsableRow key={i} row={row} state={state} dispatch={dispatch} />)}
          </TableBody>
        </Table>

        <Box sx={{ p: 2 }}>
          <form onSubmit={onSubmit}>
            <MyTextField
              label="Additional comments (optional)"
              multiline
              rows={5}
              fullWidth
              value={state.comment}
              onChange={e => dispatch({ type: 'set_comment', comment: e.target.value })}
              sx={{ mb: 2 }}
            />

            <Button
              disabled={!isValid}
              variant="contained"
              color="primary"
              onClick={onSubmit}
            >{verifyCheckedBtnText}</Button>

            {createSDVError && <Alert severity="error">Error submitting form.</Alert>}
          </form>
        </Box>
      </Paper>
    </>
  )
}
